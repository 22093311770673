/* // In your CSS or SCSS file (portfolioDetails.scss or similar) */
.pointer-cursor {
  cursor: pointer !important;
}
#pointer-cursor {
  cursor: pointer !important;
}
#pointer-text{
  cursor: text !important;
}
/* General Styles for the Navbar */
.navbar {
    position: fixed;
    width: 600px;
    height: 65px;
    top: 10px;
    left: 50%; 
    transform: translateX(-50%); 
    z-index: 1000;
    background-color: #121212; 
    border-radius: 30px; 
    padding: 10px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 3px 9px 4px #232423;
  }

  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
  }
  
  .navbar-logo {
    height: 30px; 
    margin-right: 20px;
    margin-left: 10px;
    cursor: pointer !important;
  }
  
  .navbar-links {
    list-style: none; /* Remove default list styling */
    display: flex; /* Display list items in a row */
    gap: 20px; /* Space between links */
    margin: 0;
    padding: 0;
  }
  .navbar-links li {
    display: inline;
    position: relative;
  }
  .navbar-links li:not(:first-child)::before {
    content: '•'; 
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 22px; 
  }
  
  .navbar-links a {
    color: #fff;
    text-decoration: none;
    font-size: 17px;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer !important;
  }
  
  .navbar-links a:hover {
    background-color: white;
    color:#121212;
    border-radius: 18px;
  }

  @media (max-width: 768px) {
    .navbar {
      width: 400px;
      height: 45px;
    }
    .navbar-content {
      width: 100%;
      padding: 0 5px;
    }
    .navbar-logo {
      height: 25px; 
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer !important;
    }
    .navbar-links {
      gap: 5px;
    }
    .navbar-links li:not(:first-child)::before {
      font-size: 18px; 
    }
    .navbar-links a {
      font-size: 12px;
    }
  }
  @media (max-width: 400px) {
    .navbar {
      width: 325px;
      height: 45px;
    }
    .navbar-content {
      width: 70%;
      padding: 0 5px;
    }
    .navbar-logo {
      height: 17px; 
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer !important;
    }
    .navbar-links {
      gap: 1px;
    }
    .navbar-links li:not(:first-child)::before {
      left: -2px;
      top: 55%;
      font-size: 12px; 
    }
    .navbar-links a {
      font-size: 11px;
    }
  }
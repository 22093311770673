.scroll-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 45px;
    height: 45px;
    background-color: rgb(18, 18, 18);
    border: solid 1px #ffffff;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    visibility: hidden;
    box-shadow: 0 2px 10px rgba(37, 36, 36, 0.3);
    z-index: 1000;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .scroll-up:hover {
    background-color: #ffffff;
    color:rgb(18, 18, 18);
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.3);
  }

  .scroll-up.show {
        opacity: 1;
        visibility: visible;
      }

.scroll-up span{
    font-size: 30px !important;
    font-weight: bold !important;
}
  